import React from 'react';
import { compose } from 'recompose';
import { Link } from 'gatsby'

import Layout from '../components/layout';
import {
  withAuthorization,
} from '../components/Session';
import * as ROLES from '../constants/roles';
import DashboardWrapper from '../components/Dashboard/DashboardWrapper';
import SignUpForm from '../components/SignUp';
import HeadData from "../data/HeadData"

const AddUsersPageBase = () => (
  <DashboardWrapper pageName='Add User'>
    <div className="relative md:pt-28 2xl:pt-32 pb-32 pt-20 md:px-8 px-0">
      <div className="container mx-auto px-4">
        <div className='w-full max-w-screen-lg mb-16'>
          <h2 className='text-2xl md:text-3xl 2xl:text-4xl text-gray-900 font-bold'>Add New Users</h2>
          <p className="text-base md:text-xl text-gray-800 mt-6">The Add Users Page is accessible to every signed in ADMIN user.</p>
          <p className="text-base md:text-xl text-gray-800 mt-6 italic"><strong>NOTE:</strong> Please save the password you created and share it to the new user.</p>
        </div>

        <div className='w-full max-w-sm'>
          <SignUpForm />

          <Link to='/admin'>
            <button
              type="button"
              className='group relative w-full flex justify-center py-2 px-4 my-6 border border-red-900 text-sm leading-5 font-medium rounded-md text-red-900 hover:text-white bg-white hover:bg-red-900 focus:outline-none focus:border-red-900 focus:shadow-outline-indigo active:bg-red-900 transition duration-150 ease-in-out mb-3'
            >
              Back to Manage Users Page
            </button>
          </Link>
        </div>
      </div>
    </div>
  </DashboardWrapper>
);

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const AddUsersPage = compose(
  withAuthorization(condition),
)(AddUsersPageBase);

const AddUsers = () => (
  <Layout>
    <HeadData title='Add Users' />
    <AddUsersPage />
  </Layout>
)

export default AddUsers
